import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isAbTestHeroWithoutPictureVariant: boolean }>()(
  (
    { border, palette, breakpoints, shadow },
    { isAbTestHeroWithoutPictureVariant }
  ) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(20),
    },
    searchAndTags: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      backgroundColor: '#fff',
      borderRadius: rem(16),
      border: border.main,
    },
    search: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 0,
      [breakpoints.up('md')]: {
        gap: rem(8),
        flexDirection: 'row',
        padding: `${rem(8)} ${rem(8)} ${rem(8)} ${rem(24)}`,
      },
    },
    searchInput: {
      position: 'relative',
      flexGrow: 1,
      padding: `${rem(16)} ${rem(12)}`,
      borderBottom: `${rem(1)} solid ${palette.grey[300]}`,
      [breakpoints.up('md')]: {
        padding: 0,
        border: 'none',
      },
    },
    input: {
      fontSize: rem(16),
      fontWeight: 400,
      lineHeight: rem(24),
    },

    searchBtnContainer: {
      flexShrink: 0,
      display: 'flex',
      padding: rem(8),
      [breakpoints.up('md')]: {
        padding: 0,
      },
    },
    options: {
      zIndex: 100,
      position: 'absolute',
      top: rem(60),
      left: rem(0),
      right: rem(0),
      boxShadow: shadow.high,
    },
    ratingGoogle: {
      justifyContent: 'center',
      [breakpoints.up('md')]: {
        justifyContent: isAbTestHeroWithoutPictureVariant
          ? 'center'
          : 'flex-start',
      },
    },
  })
);
