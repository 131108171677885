import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { locationOfCourse } from '@les-sherpas/sherpas-toolbox';
import { Divider } from '@mui/material';
import messages from 'src/components/LandingMozza/common/Hero/SearchBarAbTestHero/messages';
import useStyles from 'src/components/LandingMozza/common/Hero/SearchBarAbTestHero/styles';

import AddressInput from '@/components/LandingMozza/common/Hero/SearchBarAbTestHero/AddressInput/AddressInput';
import FamousTags from '@/components/LandingMozza/common/Hero/SearchBarAbTestHero/FamousTags/FamousTags';
import RatingGoogle from '@/components/LandingMozza/common/RatingGoogle/RatingGoogle';
import Button from '@/designSystem/Button/Button';
import Icon from '@/designSystem/Icon/Icon';
import { useQueryImmutable } from '@/hooks/useQueryImmutable';
import Input from '@/Molecules/Input/Input';
import Options from '@/Organisms/SearchBar/DrawerOptions/DrawerOptions';
import { orderSubjects } from '@/Organisms/SearchBar/utils';
import { apiGetSubjects } from '@/shared/API/apiGetSubjects';
import { defaultSearchSubjects } from '@/shared/defaultSearchSubjects';
import useNavigation from '@/shared/Hooks/useNavigation';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';

const SearchBarAbTestHero: FC = () => {
  const inputRef = useRef<HTMLDivElement>();

  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const { pushTo, urls } = useNavigation();
  const { isLoading, data: subjects } = useQueryImmutable(
    ['subject'],
    apiGetSubjects
  );
  const [subject, setSubject] = useState('');
  const [isSubjectOptionOpen, setIsSubjectOptionOpen] = useState(false);
  const [subjectOptions, setSubjectOptions] = useState(defaultSearchSubjects);

  const [address, setAddress] = useState<string>('');

  // search
  const onSearchClick = useCallback(() => {
    const params: { subject?: string; address?: string } = {};
    if (subject) {
      params.subject = subject;
    }

    if (address) {
      params.address = address;
    }
    pushTo(
      urls.search(
        params.subject || params.address
          ? {
              address: params.address,
              subject: params.subject,
              courseLocation: params.address
                ? locationOfCourse.atHome
                : undefined,
            }
          : undefined
      )
    );

    handleSendEventGTM('searchTeacher_heroLanding', { params });
  }, [address, pushTo, subject, urls]);

  // autocomplete
  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef?.current && !inputRef?.current?.contains(event.target)) {
        setIsSubjectOptionOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  const getOptions = (inputValue) => {
    setIsSubjectOptionOpen(true);
    const dataOptions = orderSubjects(isLoading ? [] : subjects, inputValue);
    if (dataOptions?.length > 0) {
      setSubjectOptions(dataOptions);
    } else if (inputValue.length === 0) {
      setSubjectOptions(defaultSearchSubjects);
    } else {
      setSubjectOptions([]);
    }
  };

  const updateSubjectInput = (inputSubject: string) => {
    getOptions(inputSubject);
    setSubject(inputSubject);
  };
  const onSubjectOptionClick = (option: string) => {
    setSubject(option);
    setIsSubjectOptionOpen(false);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      const input = e.target.value;
      setSubject(input);
      setIsSubjectOptionOpen(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.searchAndTags}>
        <div className={classes.search}>
          <div className={classes.searchInputs}>
            <div className={classes.searchInput} ref={inputRef}>
              <Input
                placeholder={formatMessage(messages.subject)}
                type="text"
                onKeyDown={onKeyDown}
                onChange={(e) => updateSubjectInput(e.target.value)}
                onFocus={(e) => updateSubjectInput(e.target.value)}
                value={subject}
                containerClassName={classes.input}
              >
                <Input.Adornment variant="start">
                  <Icon icon="bookOpen" />
                </Input.Adornment>
              </Input>
              {isSubjectOptionOpen && (
                <div className={classes.options}>
                  <Options
                    options={subjectOptions}
                    onClick={onSubjectOptionClick}
                  />
                </div>
              )}
            </div>
            <div className={classes.searchInput}>
              <AddressInput setAddressQuery={setAddress} />
            </div>
          </div>
          <div className={classes.searchBtnContainer}>
            <Button
              variant="primary"
              size="medium"
              isFullWidth
              onClick={onSearchClick}
              label={formatMessage(messages.find)}
            />
          </div>
        </div>
        <Divider orientation="horizontal" flexItem />

        <FamousTags />
      </div>
      <RatingGoogle gtmEvent="'gmb_heroLanding'" />
    </div>
  );
};

export default SearchBarAbTestHero;
