import { useEffect, useMemo, useState } from 'react';
import { IAddress } from '@les-sherpas/sherpas-toolbox';
import { throttle } from 'lodash-es';

import googleGeocoderService from '@/components/LandingMozza/common/Hero/SearchBarAbTestHero/AddressInput/GeocoderPlace.service';
import { GoogleApiLibraries, useGoogleApi } from '@/GoogleMap/useGoogleApi';

const G_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
const G_LIB = ['places'] as GoogleApiLibraries;

const isValidAddress = (address: IAddress) =>
  address &&
  Boolean(
    !!address.formattedAddress &&
      !!address.latitude &&
      !!address.longitude &&
      !!address.city &&
      !!address.zipCode &&
      !!address.streetNumber &&
      !!address.streetName
  );
const getAddress = async (place: PlaceType) => ({
  placeId: place.place_id,
  address: await googleGeocoderService(
    place.description,
    place.place_id,
    false
  ),
});
const autocompleteService = { current: null };

interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
  place_id: string;
}

async function getValidAddresses(results: PlaceType[]) {
  const addressEntries = await Promise.all(results.map(getAddress));
  const addressMap = Object.fromEntries(
    addressEntries.map((entry) => [entry.placeId, entry.address])
  );
  const filteredResults = results.filter((option) =>
    isValidAddress(addressMap[option.place_id])
  );
  return filteredResults;
}

const useGoogleAutocomplete = (
  inputValue,
  value,
  suggestAllTypesOfAddress: boolean
) => {
  const [options, setOptions] = useState<PlaceType[]>([]);
  useGoogleApi({
    apiKey: G_KEY,
    id: 'google-map-id',
    libraries: G_LIB,
    region: 'fr',
  });

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: {
            input: string;
            componentRestrictions: {};
            types?: string[];
          },
          callback: () => void
        ) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        },
        500
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (
      !autocompleteService.current &&
      window?.google?.maps?.places?.AutocompleteService
    ) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    const restrictions = { country: 'fr' };

    fetch(
      {
        input: inputValue,
        componentRestrictions: restrictions,
        ...(!suggestAllTypesOfAddress && { types: ['address'] }),
      },
      async (results?: PlaceType[]) => {
        if (active) {
          let newOptions = [] as PlaceType[];
          if (value) {
            newOptions = [value];
          }

          if (results) {
            const filteredResults = suggestAllTypesOfAddress
              ? results
              : await getValidAddresses(results);
            newOptions = [...newOptions, ...filteredResults];
          }

          setOptions(newOptions);
        }
      }
    );
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, suggestAllTypesOfAddress]);

  return { options, setOptions };
};

export default useGoogleAutocomplete;
