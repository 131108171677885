import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isAbTestHeroWithoutPictureVariant: boolean }>()(
  ({ palette, breakpoints }, { isAbTestHeroWithoutPictureVariant }) => ({
    container: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: rem(24), // for Navbar
      [breakpoints.up('lg')]: {
        justifyContent: 'space-between',
        gap: rem(44),
        paddingTop: rem(32), // for Navbar
      },
    },
    header: {
      marginBottom: rem(32),
      textAlign: isAbTestHeroWithoutPictureVariant ? 'left' : 'inherit',
      display: 'flex',
      flexDirection: 'column',
      gap: rem(16),
      [breakpoints.up('sm')]: {
        textAlign: isAbTestHeroWithoutPictureVariant ? 'center' : 'inherit',
      },
    },
    reasons: {
      display: 'none',
      [breakpoints.up('md')]: {
        listStyle: 'none',
        margin: `0 0 ${rem(40)}`,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: rem(16),
      },
    },
    neutralText: {
      color: palette.neutral[200],
    },
    displayInline: {
      display: 'inline',
    },
    imageContainer: {
      display: 'none',
      [breakpoints.up(1200)]: {
        display: 'block',
      },
    },
  })
);
