/* eslint-disable import/prefer-default-export */
import {
  useFeatureFlagEnabled,
  useFeatureFlagVariantKey,
} from 'posthog-js/react';

/**
 * This hook uses PostHog feature flags to determine which A/B test variant
 * is active for the "Hero" section of the landing page.
 *
 * __WARNING__ : Ensure that SEO health is maintained by avoiding the deletion
 * of HTML elements while waiting for the PostHog flags to load.
 * To do that, use isAbTestHeroLoaded to apply `opacity: 0` to the parent element
 * until the test variant is loaded, instead of removing the HTML structure.
 *
 * @returns {object} An object with booleans indicating the status of the
 * A/B test, and methods to check the specific variant.
 */
export const useAbTestHero = () => {
  const abTestName = 'abTestHero';
  const abTestVariant = useFeatureFlagVariantKey(abTestName);
  const isAbTestHeroEnabled = useFeatureFlagEnabled(abTestName);

  // Determine if the A/B test data has been loaded
  const isAbTestHeroLoaded =
    !!abTestVariant || isAbTestHeroEnabled !== undefined;

  // Identify specific test variants
  const isAbTestHeroControlVariant =
    abTestVariant === 'abTestHeroControlVariant';
  const isAbTestHeroWithoutPictureVariant =
    abTestVariant === 'abTestHeroWithoutPictureVariant';

  return {
    isAbTestHeroLoaded,
    isAbTestHeroEnabled,
    isAbTestHeroControlVariant,
    isAbTestHeroWithoutPictureVariant,
  };
};
