import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ border, breakpoints, shadow }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(20),
  },
  searchAndTags: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: rem(16),
    border: border.main,
    boxShadow: shadow.high,
    [breakpoints.up('sm')]: {
      alignSelf: 'center',
    },
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: rem(12),
    padding: rem(8),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  searchInputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  searchInput: {
    position: 'relative',
    flexGrow: 1,
    height: '100%',
  },
  input: {
    height: '100%',
  },
  searchBtnContainer: {
    flexShrink: 0,
  },
  options: {
    zIndex: 100,
    position: 'absolute',
    top: rem(60),
    left: rem(12),
    right: rem(12),
    boxShadow: shadow.high,
    [breakpoints.up('md')]: {
      top: rem(50),
      left: rem(0),
      right: rem(0),
    },
  },
}));
