import { defineMessages } from 'react-intl';

export default defineMessages({
  subject: {
    id: 'landingmozza.hero.searchbarAbTestHero.subject',
    defaultMessage: 'Matière',
  },
  find: {
    id: 'landingmozza.hero.searchbarAbTestHero.find',
    defaultMessage: 'Rechercher',
  },
});
